<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">

              <a-col :md="6" :sm="24">
                <a-form-item :label="$t('短视频内容')" prop="videoContent">
                  <a-input v-model="queryParam.videoContent" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('短视频内容')" allow-clear/>
                </a-form-item>
              </a-col>




            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>

              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['video:video:add']">-->
<!--          <a-icon type="plus" />{{$t('通用.按钮.新增')}}-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['video:video:edit']">-->
<!--          <a-icon type="edit" />{{$t('通用.按钮.修改')}}-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['video:video:remove']">-->
<!--          <a-icon type="delete" />{{$t('通用.按钮.删除')}}-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['video:video:export']">-->
<!--          <a-icon type="download" />{{$t('通用.按钮.导出')}}-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
<!--      <discuss-list ref="discussList"  @ok="getList"></discuss-list>-->

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['video:video:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['video:video:edit']">
             <a-icon type="param" />{{$t('通用.按钮.详情')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['video:video:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['video:video:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}

<!--          </a>-->
<!-- <a-divider type="vertical" v-hasPermi="['video:video:discuss']" />-->

<!--            <a @click="discussList(record)" v-hasPermi="['video:video:discuss']">-->
<!--            <a-icon type="edit" />{{$t('评论列表')}}-->
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />

    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageVideo,listVideo, delVideo } from '@/api/video/video'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import discussList from './discussList'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Video',
  components: {
    CustomDictTag,
    CreateForm,
    discussList
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        videoContent: undefined,
        videoPid: undefined,
        address: undefined,
        pubCity: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: this.$t('视频描述'),
          dataIndex: 'videoContent',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('视频播放地址'),
        //   dataIndex: 'videoUrl',
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: this.$t('创建时间'),
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('点赞次数'),
          dataIndex: 'likeNum',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: this.$t('视频商品分类id'),
        //   dataIndex: 'videoPid',
        //   ellipsis: true,
        //   align: 'center'
        // },

        {
          title: this.$t('评论数量'),
          dataIndex: 'replyNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('发布地址'),
          dataIndex: 'address',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v == "" ||v==null) {
              return "--";
            }else {
              return v;
            }
          }
        },
        {
          title: this.$t('详细地址'),
          dataIndex: 'pubCity',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v == "" ||v==null) {
              return "--";
            }else {
              return v;
            }
          }
        },
        {
          title: this.$t('状态'),
          dataIndex: 'videoState',
          ellipsis: true,
          align: 'center',
          customRender: function (v) {
            if (v == 1) {
              return "待审核";
            }else if (v==2) {
              return "审核失败";
            }else if (v==3) {
              return "审核通过";
            }
          }
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    //动态评价
    discussList(record) {
      this.$refs.discussList.title = "评论列表";
      this.$refs.discussList.show(record);
    },
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询短视频列表 */
    getList () {
      this.loading = true
     pageVideo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        videoContent: undefined,
        videoPid: undefined,
        address: undefined,
        pubCity: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVideo(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('video/video/export',
            ...that.queryParam
          , `短视频_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
